.list-title {
    color: $brand-midgray1;
    font-weight: bold;
    line-height: 13px;
}

.filter-list {
    font-size: 13px;
    .list {
        font-weight: bold;
        list-style-type: disc;
        margin: 0 0 0 15px;
    }
    .item-info {
        display: block;
    }
    .see-more-login {
        margin-top: 8px;
        display: inline-block;
    }
}

.link {
    &.link-default {
        color: $brand-midgray1;
        text-decoration: underline;
        &:hover {
            opacity: .8;
            color: $brand-midgray1;
        }
    }
    &.save-filter {
        color: #464646;
        background: none;
        display: inline-block;
        &:hover {
            opacity: .8;
            color: #464646;
        }
    }
}
