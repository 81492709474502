.select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

select,
.select select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 3px;
  padding-right: 28px;
}

select::-ms-expand {
  display: none;
}

.select2-container {
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
  width: 100%!important;
  .select2-selection--single {
    //Instead of the line below you could use @include box-sizing($bs)
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    //Instead of the line below you could use @include user-select($select)
    user-select: none;
    .select2-selection__rendered {
      // display: block;
      padding-left: 8px;
      padding-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .select2-selection__clear {
      position: relative;
    }
  }
  .select2-selection--multiple {
    //Instead of the line below you could use @include box-sizing($bs)
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    //Instead of the line below you could use @include user-select($select)
    user-select: none;
    .select2-selection__rendered {
      display: inline-block;
      overflow: hidden;
      padding-left: 8px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .select2-search--inline {
    float: left;
    .select2-search__field {
      //Instead of the line below you could use @include box-sizing($bs)
      box-sizing: border-box;
      border: none;
      font-size: 100%;
      margin-top: 8px;
      width: 100%;
      padding: 2px 0 0 10px;
    }
    .select2-search__field::-webkit-search-cancel-button {}
  }
}

.select2-container--default .select2-search--inline .select2-search__field::placeholder {
  font-size: 16px !important;
  color: #ACACAC;
}

.select2-dropdown {
  background-color: $brand-white;
  border: 1px solid $brand-white;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 4px;
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
  padding: 0 20px;
  .select2-results__options--nested  {
    li.select2-results__option {
      display: flex;
      align-items: center;
      &[aria-selected=false] {
        &:before {
          content: "";
          display: inline-block;
          border: 1px solid #000;
          height: 19px;
          width: 19px;
          min-width: 19px;
          border-radius: 3px;
          margin-right: 8px;
          background-size: 100%;
        }
      }
      &[aria-selected=true] {
        &:before {
          content: "";
          background: #000000 url(/build/images/check-white.png) center no-repeat;
          display: inline-block;
          border: 1px solid #000;
          height: 19px;
          width: 19px;
          min-width: 19px;
          border-radius: 3px;
          margin-right: 8px;
        }
      }
    }
  }
  #select2-select-country-results {
    .select2-results__options--nested  {
      li.select2-results__option {      
        &[aria-selected=false] {
          &:before {
            display: none !important;
          }
        }
        &[aria-selected=true] {
          &:before {
            display: none !important;
          }
        }
      }
    }
  }
  .select2-results__options {
    .select2-results__group {
      display: flex;
      align-items: center;
      &:before {
        content: "";
        background: url(/build/images/minus.svg) center no-repeat;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        background-size: 100%;
      }
      &.closed {
        &:before {
          content: "";
          background: url(/build/images/plus.svg) center no-repeat;
          display: inline-block;
          width: 20px;
          height: 20px;
          margin-right: 10px;
          background-size: 100%;
        }
      }
    }
  }
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  //Instead of the line below you could use @include user-select($select)
  user-select: none;
}

.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open {
  .select2-dropdown {
    left: 0;
  }
  .select2-dropdown--above {
    border-bottom: none;
    //Instead of the line below you could use @include border-bottom-left-radius($radius)
    border-bottom-left-radius: 0;
    //Instead of the line below you could use @include border-bottom-right-radius($radius)
    border-bottom-right-radius: 0;
  }
  .select2-dropdown--below {
    border-top: none;
    //Instead of the line below you could use @include border-top-left-radius($radius)
    border-top-left-radius: 0;
    //Instead of the line below you could use @include border-top-right-radius($radius)
    border-top-right-radius: 0;
    margin-top: 3px;
    // width: 500px !important;
    // left: -24px;
  }
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
  .select2-search__field {
    padding: 4px;
    width: 100%;
    //Instead of the line below you could use @include box-sizing($bs)
    box-sizing: border-box;
  }
  .select2-search__field::-webkit-search-cancel-button {}
  &.select2-search--hide {
    display: none;
  }
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: yellowgreen;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0!important;
  clip: rect(0 0 0 0)!important;
  height: 1px!important;
  margin: -1px!important;
  overflow: hidden!important;
  padding: 0!important;
  position: absolute!important;
  width: 1px!important;
}

.select2-container--classic {
  .select2-selection--single {
    &:focus {
      border: 1px solid #ACACAC;
    }
    .select2-selection__rendered {
      color: #ACACAC;
      // line-height: 28px;
    }
    .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: 700;
      margin-right: 10px;
    }
    .select2-selection__placeholder {
      color: #ACACAC;
    }
    .select2-selection__arrow b {
      // border-color: yellowgreen transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0;
      height: 0;
      left: 50%;
      // margin-left: -4px;
      margin-top: -2px;
      position: absolute;
      top: 50%;
      width: 0;
    }
  }
  &.select2-container--open {
    .select2-selection--single {
      border: 1px solid #ACACAC;
      .select2-selection__arrow {
        background: 0 0;
        border: none;
        b {
          border-color: transparent transparent yellowgreen;
          border-width: 0 4px 5px;
        }
      }
    }
    .select2-selection--multiple {
      border: 1px solid #ACACAC;
    }
    .select2-dropdown {
      border-color: #ACACAC;
    }
    &.select2-container--above .select2-selection--multiple {
      border-top: none;
      //Instead of the line below you could use @include border-top-left-radius($radius)
      border-top-left-radius: 0;
      //Instead of the line below you could use @include border-top-right-radius($radius)
      border-top-right-radius: 0;
    }
    &.select2-container--below .select2-selection--multiple {
      border-bottom: none;
      //Instead of the line below you could use @include border-bottom-left-radius($radius)
      border-bottom-left-radius: 0;
      //Instead of the line below you could use @include border-bottom-right-radius($radius)
      border-bottom-right-radius: 0;
    }
  }
  .select2-selection--multiple {
    background-color: yellowgreen;
    border: 1px solid $brand-white;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 4px;
    cursor: text;
    outline: 0;
    &:focus {
      border: 1px solid #ACACAC;
    }
    .select2-selection__rendered {
      list-style: none;
      margin: 0;
      padding: 0 5px;
    }
    .select2-selection__clear {
      display: none;
    }
    .select2-selection__choice {
      background-color: yellow;
      border: 1px solid yellowgreen;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 4px;
      cursor: default;
      float: left;
      margin-right: 5px;
      margin-top: 5px;
      padding: 0 5px;
    }
    .select2-selection__choice__remove {
      color: yellowgreen;
      cursor: pointer;
      display: inline-block;
      font-weight: 700;
      margin-right: 2px;
      &:hover {
        color: #ACACAC;
      }
    }
  }
  .select2-dropdown {
    background-color: yellowgreen;
    border: 1px solid transparent;
  }
  .select2-dropdown--above {
    border-bottom: none;
  }
  .select2-dropdown--below {
    border-top: none;
  }
  .select2-results__option[role=group] {
    padding: 0;
  }
  .select2-results__option[aria-disabled=true] {
    color: red;
  }
  .select2-results__option--highlighted[aria-selected] {
    background-color: #ACACAC;
    color: yellowgreen;
  }
  .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
  }
  .select2-results>.select2-results__options {
    max-height: 200px;
    overflow-y: auto;
  }
  .select2-search--dropdown .select2-search__field {
    border: 1px solid yellowgreen;
    outline: 0;
  }
  .select2-search--inline .select2-search__field {
    outline: 0;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: none;
  }
}

.select2-container--default {
  .select2-selection--single {
    background-color: $brand-white;
    border: 1px solid $brand-white;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    padding: 6px 12px;
    height: 40px;
    border-radius: 4px;
    text-align: left;
    .select2-selection__rendered {
      line-height: 26px;
      font-size: 16px;
      color: #ACACAC;
    }
    .select2-selection__placeholder {
      color: #ACACAC;
    }
    .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: 700;
    }
    .select2-selection__arrow {
      height: 26px;
      position: absolute;
      top: 20px;
      right: 10px;
      width: 20px;
      b {
        border-color: #333 transparent transparent;
        border-style: solid;
        border-width: 7px 5px 0;
        height: 0;
        left: 50%;
        margin-top: -2px;
        position: absolute;
        width: 0;
      }
    }
  }
  .select2-results__option[aria-disabled=true] {
    color: #ACACAC;
  }
  &.select2-container--disabled {
    .select2-selection--single {
      background-color: #ACACAC;
      cursor: default;
      .select2-selection__clear {
        display: none;
      }
    }
    .select2-selection--multiple {
      background-color: #ACACAC;
      cursor: default;
    }
    .select2-selection__choice__remove {
      display: none;
    }
  }
  &.select2-container--open {
    &.select2-container--above {
      .select2-selection--multiple {
        //Instead of the line below you could use @include border-top-left-radius($radius)
        border-top-left-radius: 0;
        //Instead of the line below you could use @include border-top-right-radius($radius)
        border-top-right-radius: 0;
      }
      .select2-selection--single {
        //Instead of the line below you could use @include border-top-left-radius($radius)
        border-top-left-radius: 0;
        //Instead of the line below you could use @include border-top-right-radius($radius)
        border-top-right-radius: 0;
      }
    }
    &.select2-container--below {
      .select2-selection--multiple {
        //Instead of the line below you could use @include border-bottom-left-radius($radius)
        border-bottom-left-radius: 0;
        //Instead of the line below you could use @include border-bottom-right-radius($radius)
        border-bottom-right-radius: 0;
      }
      .select2-selection--single {
        //Instead of the line below you could use @include border-bottom-left-radius($radius)
        border-bottom-left-radius: 0;
        //Instead of the line below you could use @include border-bottom-right-radius($radius)
        border-bottom-right-radius: 0;
      }
    }
    .select2-selection--single .select2-selection__arrow b {
      // border-color: transparent transparent yellowgreen;
      border-width: 0 5px 7px;
    }
  }
  .select2-selection--multiple {
    background-color: $brand-white;
    border: 1px solid $brand-white;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0;
    cursor: text;
    .select2-selection__rendered {
      //Instead of the line below you could use @include box-sizing($bs)
      box-sizing: border-box;
      list-style: none;
      margin: 0;
      width: 100%;
      padding: 6px 7px;
      li {
        list-style: none;
        position: relative;
        // width: 100%;
        &::placeholder {
          width: 100% !important;
        }
      }
    }
    .select2-selection__placeholder {
      // color: #ACACAC;
      margin-top: 5px;
      float: left;
    }
    .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: 700;
      margin-right: 10px;
      color: yellowgreen;
      margin-top: 11px;
    }
    .select2-selection__choice {
      border: 1px solid #F1F0EC;
      cursor: default;
      float: left;
      margin-right: 5px;
      color: #000000;
      margin-top: 7px;
      padding: 4px 33px 5px 17px;
      background-color: #F1F0EC;
      border-radius: 36px;
      font-size: 14px;
      line-height: 1.5em;
    }
    .select2-selection__choice__remove {
      cursor: pointer;
      display: inline-block;
      font-weight: 700;
      color: #000000;
      margin-right: 5px;
      position: absolute;
      right: 0;
      font-size: 0;
      &:after {
        background: #000000 url("/build/images/close-cross.svg") center no-repeat;
        display: inline-block;
        content: "";
        color: $brand-white;
        width: 22px;
        height: 22px;
        border-radius: 100%;
        opacity: .5;
      }
    }
    &:hover {
      .select2-selection__choice__remove::after {
        opacity: 1;
      }
    }
  }
  .select2-results__option[role=group] {
    padding: 0;
  }
  .select2-results__option[aria-selected=true] {
    opacity: 1;  
  }
  .select2-results__option--highlighted[aria-selected] {
    background-color: #F1F0EC;
    color: #363636;
  }
  .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
  }
  .select2-results>.select2-results__options {
    max-height: 200px;
    overflow-y: auto;
    font-size: 16px;
  }
  &.select2-container--focus .select2-selection--multiple {
    border: 1px solid #000;
    outline: 0;
    border-color: $brand-white;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    // box-shadow: inset 0 1px 1px $color_picton_blue_60_approx, 0 0 8px $color_picton_blue_60_approx;
  }
  .select2-search--dropdown .select2-search__field {
    border: 1px solid yellowgreen;
  }
  .select2-search--inline .select2-search__field {
    background: 0 0;
    border: none;
    outline: 0;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: none;
  }
  .select2-results__option .select2-results__option {
    padding-left: 1em;
    .select2-results__group {
      padding-left: 0;
    }
    .select2-results__option {
      // margin-left: -1em;
      padding-left: 2em;
      .select2-results__option {
        // margin-left: -2em;
        padding-left: 3em;
        .select2-results__option {
          // margin-left: -3em;
          padding-left: 4em;
          .select2-results__option {
            // margin-left: -4em;
            padding-left: 5em;
            .select2-results__option {
              // margin-left: -5em;
              padding-left: 6em;
            }
          }
        }
      }
    }
  }
}

.select2-container--default[dir=rtl] {
  .select2-selection--single {
    .select2-selection__clear {
      float: left;
    }
    .select2-selection__arrow {
      left: 1px;
      right: auto;
    }
  }
  .select2-selection--multiple {
    .select2-search--inline {
      float: right;
    }
    .select2-selection__choice {
      float: right;
      // margin-left: 5px;
      margin-right: auto;
    }
    .select2-selection__placeholder {
      float: right;
    }
    .select2-selection__choice__remove {
      // margin-left: 2px;
      margin-right: auto;
    }
  }
}

.select2-container--classic[dir=rtl] {
  .select2-selection--single {
    .select2-selection__clear {
      float: left;
    }
    .select2-selection__arrow {
      border: none;
      border-right: 1px solid yellowgreen;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 4px 0 0 4px;
      left: 1px;
      right: auto;
    }
  }
  .select2-selection--multiple {
    .select2-selection__choice {
      float: right;
      // margin-left: 5px;
      margin-right: auto;
    }
    .select2-selection__choice__remove {
      // margin-left: 2px;
      margin-right: auto;
    }
  }
}

.select {
  // margin-left:0px;
}

.select select,
select {
  background: $brand-white;
  height: 40px;
  width: 100%;
  padding: 0 15px;
}

#kbc .ibk #opportunities .opportunities--list .opportunities--filter select {
  background: none;
}
