@font-face {
    font-family: 'SantanderHeadlineW05';
    src: url('/build/fonts/SantanderHeadlineW05-Italic.eot');
    src: url('/build/fonts/SantanderHeadlineW05-Italic.eot?#iefix') format('embedded-opentype'),
        url('/build/fonts/SantanderHeadlineW05-Italic.woff2') format('woff2'),
        url('/build/fonts/SantanderHeadlineW05-Italic.woff') format('woff'),
        url('/build/fonts/SantanderHeadlineW05-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'SantanderHeadlineW05-Rg';
    src: url('/build/fonts/SantanderHeadlineW05-Rg.eot');
    src: url('/build/fonts/SantanderHeadlineW05-Rg.eot?#iefix') format('embedded-opentype'),
        url('/build/fonts/SantanderHeadlineW05-Rg.woff2') format('woff2'),
        url('/build/fonts/SantanderHeadlineW05-Rg.woff') format('woff'),
        url('/build/fonts/SantanderHeadlineW05-Rg.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SantanderTextW05';
    src: url('/build/fonts/SantanderTextW05-Regular.eot');
    src: url('/build/fonts/SantanderTextW05-Regular.eot?#iefix') format('embedded-opentype'),
        url('/build/fonts/SantanderTextW05-Regular.woff2') format('woff2'),
        url('/build/fonts/SantanderTextW05-Regular.woff') format('woff'),
        url('/build/fonts/SantanderTextW05-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SantanderTextW05-LightIt';
    src: url('/build/fonts/SantanderTextW05-LightIt.eot');
    src: url('/build/fonts/SantanderTextW05-LightIt.eot?#iefix') format('embedded-opentype'),
        url('/build/fonts/SantanderTextW05-LightIt.woff2') format('woff2'),
        url('/build/fonts/SantanderTextW05-LightIt.woff') format('woff'),
        url('/build/fonts/SantanderTextW05-LightIt.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'SantanderTextW05';
    src: url('/build/fonts/SantanderTextW05-Bold.eot');
    src: url('/build/fonts/SantanderTextW05-Bold.eot?#iefix') format('embedded-opentype'),
        url('/build/fonts/SantanderTextW05-Bold.woff2') format('woff2'),
        url('/build/fonts/SantanderTextW05-Bold.woff') format('woff'),
        url('/build/fonts/SantanderTextW05-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'SantanderHeadlineW05-BoldIt';
    src: url('/build/fonts/SantanderHeadlineW05-BoldIt.eot');
    src: url('/build/fonts/SantanderHeadlineW05-BoldIt.eot?#iefix') format('embedded-opentype'),
        url('/build/fonts/SantanderHeadlineW05-BoldIt.woff2') format('woff2'),
        url('/build/fonts/SantanderHeadlineW05-BoldIt.woff') format('woff'),
        url('/build/fonts/SantanderHeadlineW05-BoldIt.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'SantanderTextW05';
    src: url('/build/fonts/SantanderTextW05-BoldItalic.eot');
    src: url('/build/fonts/SantanderTextW05-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/build/fonts/SantanderTextW05-BoldItalic.woff2') format('woff2'),
        url('/build/fonts/SantanderTextW05-BoldItalic.woff') format('woff'),
        url('/build/fonts/SantanderTextW05-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'SantanderTextW05';
    src: url('/build/fonts/SantanderTextW05-Light.eot');
    src: url('/build/fonts/SantanderTextW05-Light.eot?#iefix') format('embedded-opentype'),
        url('/build/fonts/SantanderTextW05-Light.woff2') format('woff2'),
        url('/build/fonts/SantanderTextW05-Light.woff') format('woff'),
        url('/build/fonts/SantanderTextW05-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'SantanderHeadlineW05';
    src: url('/build/fonts/SantanderHeadlineW05-Bold.eot');
    src: url('/build/fonts/SantanderHeadlineW05-Bold.eot?#iefix') format('embedded-opentype'),
        url('/build/fonts/SantanderHeadlineW05-Bold.woff2') format('woff2'),
        url('/build/fonts/SantanderHeadlineW05-Bold.woff') format('woff'),
        url('/build/fonts/SantanderHeadlineW05-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'SantanderTextW05';
    src: url('/build/fonts/SantanderTextW05-Italic.eot');
    src: url('/build/fonts/SantanderTextW05-Italic.eot?#iefix') format('embedded-opentype'),
        url('/build/fonts/SantanderTextW05-Italic.woff2') format('woff2'),
        url('/build/fonts/SantanderTextW05-Italic.woff') format('woff'),
        url('/build/fonts/SantanderTextW05-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}


@font-face {
    font-family: 'santander-micro-text';
    src: url('/build/fonts/SantanderMicroText.ttf') format('ttf'),
    url('/build/fonts/SantanderMicroText.woff2') format('woff2'),
    url('/build/fonts/SantanderMicroText.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'santander-micro-text-bold';
    src: url('/build/fonts/SantanderMicroText-Bd.ttf') format('ttf'),
    url('/build/fonts/SantanderMicroText-Bd.woff2') format('woff2'),
    url('/build/fonts/SantanderMicroText-Bd.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'santander-headline-light';
    src: url('/build/fonts/SantanderHeadlineLight.ttf') format('ttf'),
    url('/build/fonts/SantanderHeadlineLight.woff2') format('woff2'),
    url('/build/fonts/SantanderHeadlineLight.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'santander-headline-regular';
    src: url('/build/fonts/SantanderHeadline-Regular.ttf') format('ttf'),
    url('/build/fonts/SantanderHeadline-Regular.woff2') format('woff2'),
    url('/build/fonts/SantanderHeadline-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'santander-headline-bold';
    src: url('/build/fonts/SantanderHeadline-Bold.ttf') format('ttf'),
    url('/build/fonts/SantanderHeadline-Bold.woff2') format('woff2'),
    url('/build/fonts/SantanderHeadline-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}


//From club
$BentonLight: "BentonSans-ExtraLight", Arial, Helvetica, sans-serif;
$BentonMedium: "BentonSans-Medium", Arial, Helvetica, sans-serif;
$BentonBold: "BentonSans-Bold", Arial, Helvetica, sans-serif;
