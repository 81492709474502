#feed-opportunity {
    .title {
        color: $brand-midgray1;
    }
    .items-wrapper {
        display: flex;
        flex-direction: row;
        margin-bottom: 16px;
        .item-right {
            margin-left: auto;
        }
        .item {
            line-height: 12px;
            .element {
                display: inline-block;
                width: 100%;
            }
        }
        .item-left {
            .tilte {
                color: $brand-midgray1;
                font-size: 15px;
                font-weight: 600;
                line-height: 20px;
                margin-bottom: 5px;
            }
            .subtitle {
                color: #818181;
                font-size: 13px;
                line-height: 14px;
                margin-bottom: 5px;
            }
            .location {
                color: $brand-midgray1;
                font-size: 12px;
                line-height: 18px;
            }
            .fa-map-marker-alt {
                margin-right: 5px;
            }
        }
    }
    .section-content {
        padding-bottom: 15px;
        font-family: 'santander-headline-regular', Arial, sans-serif;
    }
}

.link {
    color: $brand-red;
    font-size: 13px;
    line-height: 18px;
    &.link-underline {
        text-decoration: underline;
    }
    &:hover {
        opacity: .8;
        color: $brand-red;
    }
}

.section-content {
    margin-bottom: 17px;
    border-bottom: 1px solid #D1D1D1;
}

.morecontent span {
    display: none;
}
.seemorelink {
    display: block;
    color: $brand-red;
    &:hover {
        color: $brand-red;
        opacity: .8;
    }
}
