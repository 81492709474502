.bloc-subtile {
  font-size: 25px;
}

.cta {
  font-family: "santander-micro-text-bold", Arial, sans-serif;
  font-size: 1.125em;
  padding: .4em 1.2em .5em;
  text-align: center;
  letter-spacing: normal;
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid $brand-red;
  &.cta-btn-1 {
  }
  &.cta-btn-2 {
    padding: 0.35em 1.2em 0.45em;
    font-size: 0.95em;
    border-radius: 20px;
    @include mobile {
      font-size: 1em;
    }
  }
  &.cta-btn-3 {

  }
  &.cta-fliter {
    text-align: left;
    height: 33px;
    padding: 10px 26px 10px 15px;
    border-radius: 36px;
    border: none;
    font-size: 0.8em;
    line-height: 0.85em;
    width: 100%;
    background-color: #f1f0ec;
    color: #000000;
    opacity: 1;
    .icon-filter {
      opacity: 0.5;
    }
    &.text-ellipsis {
      width: 189px;
    }
    &:hover {
      .active:after {
        opacity: 1;
      }
      .cta-label {
        opacity: 1;
      }
      .icon-filter {
        opacity: 1;
      }
    }
  }
}

a {
  &.cta {
    -webkit-appearance: none;
    display: inline-block;
    color: #fff;

    &:hover {
      color: $brand-red;
      text-decoration: none;
    }

    &.cta-btn-3 {
      color: $brand-red;

      &:hover {
        color: #fff;
        border: 1px solid $brand-red;
      }
    }
  }
}

.cta-btn-fill {
  background: $brand-red;
  color: $brand-white;
  &:hover {
    background: $brand-white;
    border: 1px solid $brand-red;
    color: $brand-red;
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  width: 315px;
  .cta-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    min-height: 20px;
  }
}

.cta-btn-line {
  background: $brand-white;
  color: $brand-red;
  border: 1px solid $brand-red;
  &:hover {
    background: $brand-red;
    color: $brand-white;
  }
}

.cta-filter-fill {
  background: $brand-white_smoke;
  color: $brand-midgray1;
  &:hover {
    .cta-label {
      opacity: 0.5;
    }
  }
}

.cta.cta-filter-fill-1 {
  background: $brand-white;
  color: $brand-red;
  border: 1px solid $brand-red;
  width: auto;
  padding: 9px 15px 9px 30px;
  .icon-filter.icon-add {
    opacity: 1;
    background: url("/build/images/add-red.svg") center no-repeat;
  }
  &:hover {
    color: $brand-white;
    background: $brand-red;
    border: 1px solid $brand-red;
    .icon-filter.icon-add {
      opacity: 1;
      background: url("/build/images/add.svg") center no-repeat;
    }
  }
}

.cta-filter-line {
  background: $brand-white;
  color: $brand-red;
  border: 1px solid $brand-red;
  &:hover {
    color: $brand-white;
    background: $brand-red;
    .add:after {
      opacity: 1;
    }
  }
}

.add {
  position: relative;
  display: inline-block;
  width: 100%;
}

.cta {
  .active {
    position: relative;
    display: inline-block;
    width: 100%;
  }
}

.cta-label_bloc {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-filter {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 100%;
  right: -20px;
  position: absolute;
  top: -4px;
  &.icon-active {
    background: #000000 url("/build/images/close-cross.svg") center no-repeat;
  }
  &.icon-add {
    background: url("/build/images/add.svg") center no-repeat;
    right: auto;
    left: -25px;
  }
}

/*-- btn club spinner --*/
.cta.over-layed-loader {
  position: relative;
  overflow: hidden;
  pointer-events: none;
  z-index: 0;
  .spinner {
    position: absolute;
    margin: 0 !important;
    border-width: 0.2em;
    width: 1rem;
    height: 1rem;
    left: calc(50% - 0.5rem);
    top: calc(50% - 0.5rem);
    z-index: 2;
  }
  .cta-label_bloc {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
}