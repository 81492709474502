.modal-title {
    font-family: "santander-micro-text-bold", Arial, sans-serif;
    font-size: 1em;
    color: #000000;
}

.modal-header {
    border: 0 !important;
    padding: 0 0 15px !important;
    .close {
        color: #EB1C25;
        font-size: 13px;
        font-weight: 600;
        line-height: 18px;
        text-align: right;
        opacity: 1;
        text-decoration: underline;
    }
}

.modal-content {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.29);
    border: none !important;
    border-radius: 0 !important;
    padding: 20px 23px !important;
}

.modal.show .modal-dialog {
    left: -46px;
    @include mobile {
        left: 0;
    }
}

.modal-body {
    padding: 0 !important;
}

.modal-open {
    .sticky-top {
        position: static !important;
    }
}

.modal-content span#modale-interex-no-result{
    display: none;
}
