.wallpage {
    background: #ffffff url("/build/images/bg-wall_containers-gradient.jpg") center 0 no-repeat;
    display: inline-block;
    width: 100%;
    margin: 0 auto;
    background-size: 100%;
    position: relative;
    @include mobile {
        background-size: contain;
    }
    .logo-section {
        margin: 57px 0 47px;
        .title {
            text-align: right;
            font-size: 16px;
            line-height: 22px;
            text-shadow: 0 0 8px rgba(0, 0, 0, .19);
            .subtile {
                font-weight: bold;
            }
            @include mobile {
                color: #424242;
                text-align: center;
            }
        }
        .logo {
            width: 338px;
            @include mobile {
                width: 280px;
            }
        }
    }
}
