/*.bloc-subtile {
    font-size: 25px;
}

.cta {
    padding: 10px 27px;
    min-width: 135px;
    text-align: center;
    border: none;
    font-weight: bold;
    letter-spacing: 1px;
    cursor: pointer;
    &.cta-btn-1 {
        height: 40px;
        border-radius: 20px;
        font-size: 15px;
        line-height: 15px;
    }
    &.cta-btn-2 {
        height: 33px;
        border-radius: 16.5px;
        font-size: 13px;
        line-height: 13px;
    }
    &.cta-btn-3 {
        height: 33px;
        border-radius: 16.5px;
        font-size: 13px;
        line-height: 11px;
    }
    &.cta-fliter {
        text-align: left;
        height: 33px;
        padding: 10px 26px 10px 15px;
        border-radius: 16.5px;
        font-size: 13px;
        line-height: 13px;
        width: 100%;
        &.text-ellipsis {
            width: 189px;
        }
        &:hover {
            .active:after {
                opacity: 1;
            }
            .icon-filter {
                opacity: 1;
            }
        }
    }
}

a {
    &.cta {

        -webkit-appearance: none;
        display: inline-block;
        color: #fff;

        &:hover {
            color: $brand-red;
            text-decoration: none;
        }

        &.cta-btn-3 {
            color: $brand-red;

            &:hover {
                color: #fff;
                border: 1px solid $brand-red;
            }
        }
    }
}

.cta-btn-fill {
    background: $brand-red;
    border: 1px solid $brand-red;
    color: $brand-white;
    &:hover {
        background: $brand-white;
        border: 1px solid $brand-red;
        color: $brand-red;
    }
}

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    width: 315px;
    .cta-label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        -ms-text-overflow: ellipsis;
    }
}

.cta-btn-line {
    background: $brand-white;
    color: $brand-red;
    border: 1px solid $brand-red;
    &:hover {
        background: $brand-red;
        color: $brand-white;
        border: none;
    }
}

.cta-filter-fill {
    background: $brand-white_smoke;
    color: $brand-midgray1;
    &:hover {
        .cta-label {
            opacity: 0.5;
            &.active:after {
                opacity: 1;
            }
        }
    }
}

.cta-filter-line {
    background: $brand-white;
    color: $brand-red;
    border: 1px solid $brand-red;
    &:hover {
        color: $brand-white;
        background: $brand-red;
        .add:after {
            opacity: 1;
        }
    }
}

.cta-filter-fill-1 {
    background: $brand-red;
    color: $brand-white;
    border: 1px solid $brand-red;
    &:hover {
        color: $brand-red;
        background: $brand-white;
        border: 1px solid $brand-red;      
    }
    .icon-filter {
        opacity: 1;
    }
}

.add {
    position: relative;
    display: inline-block;
    width: 100%;
}

.cta {
    .active {
        position: relative;
        display: inline-block;
        width: 100%;
    }
}

.cta-label_bloc {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-filter {
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 100%;
    opacity: .25;
    right: -20px;
    position: absolute;
    top: -4px;
    &.icon-active {
        background: url("/build/images/close-cross.svg") center no-repeat;
        background-color: $brand-red;
    }
    &.icon-add {
        background: url("/build/images/add.svg") center no-repeat;
        background-color: $brand-red;
    }
}
*/