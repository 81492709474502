.bloc-see-more {
  text-align: center;
  .title {
    color: $brand-red;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .content {
    font-size: 18px;
    color: $brand-midgray1;
  }
}

.see-more-item {
  justify-content: center;
}

.see-more-wrapper {
  padding-top: 150px;
  top: -160px;
  margin-bottom: -130px;
  background: -moz-linear-gradient(top, rgba(125, 185, 232, 0) 0%, $brand-white 40%);
  background: -webkit-linear-gradient(top, rgba(125, 185, 232, 0) 0%, $brand-white 40%);
  background: linear-gradient(to bottom, rgba(125, 185, 232, 0) 0%, $brand-white 40%);
}

.more-result-wrapper {
  margin: 30px 0 60px;
  .bloc-see-more {
    .cta {
      font-size: 0.9em;
    }
  }
}
