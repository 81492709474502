@import '_variablecolors';

.title {
    // font-weight: bold;
    color: $brand-gray;
}

.section-title_1 {
    font-size: 55px;
}

.section-title_2 {
    font-size: 30px;
}

.section-title_3 {
    font-size: 20px;
}

.section-title_4 {
    font-size: 18px;
}

.section-title_5 {
    font-size: 15px;
}
