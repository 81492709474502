.card {
    background: $brand-white;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    padding: 18px 28px;
    font-size: 1em;
    margin-bottom: 23px;
    border-radius: 14px !important;
    &.tile {
        font-family: "santander-headline-regular", Arial, sans-serif;
        font-size: 1.125em;
        background-color: #E2E9EB;
        color: #206376;
        .tile-action {
            font-size: 0.9em;
            color: #000000;
            margin-bottom: 0;
            margin-top: 1.15em;
            a {
                font-family: "santander-headline-bold", Arial, sans-serif;
                color: #000000;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
.card-tile-component {
    .card {
        padding-top: 12px;
        padding-bottom: 12px;
        ul {
            margin-bottom: 0.5em;
        }
    }
}
.card-head {
    padding-bottom: 0.15em;
    display: flex;
    align-items: center;
    .head-item {
        display: inline-block;
        &.head-item-left {
            letter-spacing: 1px;
            max-width: calc(100% - 150px);
            .head-title {
                font-family: "santander-headline-bold", Arial, sans-serif;
                color: $brand-red;
                font-size: 1.125em;
                line-height: 24px;
                text-transform: uppercase;
                margin: 15px 0 9px;
                display: inline-block;
            }
        }
        &.head-item-right {
            float: right;
            color: #B6B6B6;
            font-size: 12px;
            margin-left: auto;
        }
    }

    .card-title-link {        
        &:hover {
            text-decoration: none;
            .head-title {
                text-decoration: underline;
            }
        }
    }
}

.tag-info {
    font-size: 13px;
    line-height: 18px;
    text-transform: uppercase;
    font-weight: 500;
    padding: 4px 15px;
    border: 1px solid #6F6F6F;
    border-radius: 5px;
    margin: 15px 0 9px;
    display: inline-block;
    margin-left: auto;
    position: relative;
    min-width: 110px;
    max-width: 140px;
    text-align: center;
}

.card-content {
    color: #000000;
    .event {
        margin-bottom: 0;
        text-transform: uppercase;
    }
    .head-item {
        margin: 12px 0 5px;
        display: inline-block;
    }
}
.lock-calendar-details {
    margin-bottom: 2em;
    .eeicon {
        font-size: 22px;
        color: #000000;
        margin-right: 10px;
        vertical-align: bottom;
    }
    .head-item-right {
        color: #818181;
        font-size: 14px;
        background: url("/build/images/calendar-plus.svg") left center no-repeat;
        background-size: 19px auto;
        padding-left: 25px;
        margin: 0;
    }
}

.details {
    position: relative;
    align-items: center;
    margin-bottom: 10px;
    font-size: 14px;
    .label {
        color: #A7A7A7;
        margin-bottom: 0.5em;
    }
    .col-picto {
        width: auto;
        text-align: center;
        img {
            height: 50px;
        }
    }
    /*&:after {
        content: "";
        width: 2px;
        height: 100%;
        background: #979797;
        position: absolute;
        top: 0;
        right: 15px;
        @include mobile {
            display: none;
        }
    }
    &.details-right {
        &::after {
            display: none;
        }
    }*/
}

.details-description {
    line-height: 24px;
    margin-bottom: 2em;
    font-size: 1em;
    &::after {
        display: none;
    }
    .description {
        margin-bottom: 0;
    }
}

.event-purpose {
    font-weight: 200;
}

.event-title {
    font-weight: bold;
}

.col-text {
    margin-left: 8px;
    p {
        margin-bottom: 0;
    }
}

.event-tag {
    font-style: italic;
    .tag-title {
        line-height: 24px;
        margin-bottom: 3px;
        font-weight: bold;
        font-size: 15px;
    }
    .tag-text {
        p {
            margin-bottom: 0;
        }
    }
    .tag-btn {
        width: 30%;
        text-align: right;
        align-self: flex-end;
    }
}

.wrapper-info {
    display: flex;
}

// card update content
.card-update-content {
    display: flex;
    .col-right {
        margin-top: 15px;
        padding-left: 15px;
        margin-left: auto;
    }
}

.card-update-bottom {
    font-size: 13px;
    display: flex;
    .label {
        color: #ADADAD;
        min-width: 70px;
        display: inline-block;
    }
}

// Card tenders
.tenders-content {
    .head {
        .event-title {
            font-size: 24px;
            line-height: 24px;
            margin-bottom: 6px;
        }
    }
    .description {
        p {
            margin-bottom: 0;
        }
        .text-ellipsis {
            width: 435px;
            @include mobile {
                width: 100%;
            }
        }
        .label {
            min-width: 110px;
            display: inline-block;
        }
        p {
            line-height: 24px;
            margin-bottom: 0;
        }
    }
    .col-right {
        margin-top: auto;
    }
}

// card report
.report-content {
    display: flex;
    .col-right {
        margin-left: auto;
        text-align: right;
        margin-top: 15px;
    }
    .description {
        p {
            margin-bottom: 0;
        }
    }
    .col-left {
        .head {
            margin-bottom: 10px;
        }
    }
}

.action-wrap {
    margin-top: 22px;
}

.eeicon {
    font-size: 90px;
    color: #DEDEDE;
    &.eeicon-organizer,
    &.eeicon-location,
    &.eeicon-event,
    &.eeicon-calendar {
        font-size: 17px;
        color: #818181;
    }
}

// card buisiness
.business-content {
    .tag-info {
        margin-bottom: -5px;
    }
    .description {
        .text-ellipsis {
            width: 435px;
            @include mobile {
                width: 100%;
            }
        }
        .label {
            min-width: 110px;
            display: inline-block;
        }
        p {
            line-height: 24px;
            margin-bottom: 0;
        }
    }
}

.wrapper-location {
    display: flex;
    align-items: center;
    .company-logo {
        margin-left: auto;
        height: 80px;
    }
    .location-city {
        a {
            color: $brand-midgray1;
            font-size: 12px;
            font-weight: bold;
            line-height: 24px;
        }
        .city {
            padding-left: 5px;
        }
        .label-name {
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 0;
        }
    }
}

.card-title-link {
    color: $brand-midgray1;
    &:hover {
        color: $brand-midgray1;
        text-decoration: underline;
    }
}

.tag-trade-show {
    font-family: "santander-micro-text-bold", Arial, sans-serif;
    font-size: 14px;
    padding: 2px 10px;
    background-color: #DEB72C;
    color: $brand-white;
    border: none;
}

.tag-report {
    font-family: "santander-micro-text-bold", Arial, sans-serif;
    font-size: 14px;
    padding: 2px 10px;
    background-color: #F92930;
    color: $brand-white;
    border: none;
}

.tag-tenders {
    font-family: "santander-micro-text-bold", Arial, sans-serif;
    font-size: 14px;
    padding: 2px 10px;
    background-color: #33B771;
    color: $brand-white;
    border: none;
}

.tag-business {
    font-family: "santander-micro-text-bold", Arial, sans-serif;
    font-size: 14px;
    padding: 2px 10px;
    background-color: #2DB1B9;
    color: $brand-white;
    border: none;
}

.tag-content-update {
    font-family: "santander-micro-text-bold", Arial, sans-serif;
    font-size: 14px;
    padding: 2px 10px;
    background-color: #B23D67;
    color: $brand-white;
    border: none;
}

.tag-webinar {
    font-family: "santander-micro-text-bold", Arial, sans-serif;
    font-size: 14px;
    padding: 2px 10px;
    background-color: #006AF0;
    color: $brand-white;
    border: none;
}

// Card video 
.video-section {
    padding: 50px;
    text-align: center;
    .title {
        color: $brand-red;
        font-weight: bold;
        font-size: 25px;
    }
    .video-container {
        max-width: 1200px;
        margin: 50px auto 0;
        * {
            width: 100%;
        }
    }
}