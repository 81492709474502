.filter-wrapper {
    background: $brand-white;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    padding: 18px 22px;
    font-size: 1em;
    margin-bottom: 23px;
    width: 100%;
    overflow-y: auto;
    max-height: 825px;
    border-radius: 14px;
    .head-bloc {
        background-color: #F3F3F3;
        border-radius: 14px;
        width: 100%;
        display: inline-block;
        padding: 0.75em 1em;
        margin-bottom: 1.5em;
        .title {
            font-family: "santander-micro-text-bold", Arial, sans-serif;
            color: #000000;
            margin-bottom: 6px;
        }
        .subtitle {
            font-family: 'santander-headline-regular', Arial, sans-serif;
            color: #6c6c6c;
            margin-bottom: 5px;
        }
    }   
}

.filter-title {
    font-family: "santander-micro-text-bold", Arial, sans-serif;
    color: #000000;
    margin-bottom: 8px;
    margin-top: 1em;
}

.cta-tag {
    border: none;
    border-radius: 8px;
    background-color: $brand-white;
    padding: 0;
    text-transform: uppercase;
    letter-spacing: 2px;
    cursor: pointer;
    .tag-label {
        color: #009697;
        font-size: 12px;
        font-weight: bold;
        line-height: 17px;
        vertical-align: middle;
        text-align: left;
    }
    .picto {
        margin-right: 8px;
    }
}

.filter-bloc {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #D1D1D1;
    &:last-child {
        border: none;
        padding-bottom: 0;
    }
    &.bloc-country {
        padding: 0;
        border: none;
    }
}

.interex-list {
    margin-bottom: 0;
    .interex-item {
        margin-bottom: 8px;
    }
    .cta-fliter.text-ellipsis {
        width: 100%;
    }
}

button,
.btn {
    outline-width: 0;
    outline: 0;
    &:focus,
    &:active,
    &:active {
        outline-width: 0;
        outline: 0 !important;
        box-shadow: none !important;
    }
}

#save_filter {
    width: 100%;
}