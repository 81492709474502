@import './styleguides/_headings.scss';
@import './styleguides/_fonts.scss';
@import './styleguides/_variablecolors.scss';
@import './styleguides/_home.scss';
@import './styleguides/_wall.scss';

@import './abstracts/_mixins.scss';
@import 'components/buttons_generic';
@import 'components/button';
@import 'components/card';
@import 'components/filter.scss';
@import 'components/filter-info-list.scss';
@import 'components/modal-filter.scss';
@import 'components/bloc';
@import 'components/bloc-opportunity';
// @import 'components/header';
@import 'components/footer';
@import 'components/select2-interex';
// @import 'modal-login';
@import 'components/select2';
@import 'components/_see-more-login.scss';
@import '../../public/build/fonts/eefont.css';
@import 'components/error-page';

/* Bootstrap */

@import "~bootstrap/scss/bootstrap";

/* Bootstrap tooltip */

@import "~bootstrap/scss/tooltip";
/* layout */
@import './components/_layout.scss';

.login-off {
    display: none;
}

.sticky-top {
    top: 50px !important;
    z-index: 0 !important;
}

#card-load {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    text-align: center;
    margin: 0;
    border: none;
    z-index: 100;
    .spinnerPage {
        width: 75px;
        height: 75px;
        position: fixed;
        top: calc(50% - 40px);
        left: calc(50% - 36px);
        background-image: url(/build/images/spinner-white.png);
        animation: 3s linear infinite spinner-border;
    }
}


#modal-filter-interex,
#modal-filter-country {
    .modal-body {
        border: 1px solid #ccc;
    }
}

.save-filter {
    border:none;
}

#card-load {
    display: none;
}

#user_preferences_interex {
    //display: none;
}

.input-filter-interex {
    width: 100%;
    padding: 5px 10px;
}