@import '../abstracts/_mixins.scss';
.bg-home {
    background: url("/build/images/bg-wall_containers.jpg") center 0 no-repeat;
    width: 100%;
    background-size: cover;
    padding-top: 84px;
    z-index: 1;
    min-height: calc(100vh - 413px);
    &:after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        background: #000;
        opacity: .25;
        display: inline-block;
        top: 0;
        right: 0;
        z-index: -1;
    }
}

.hub {
    text-align: left;
    position: relative;
    z-index: 1;
    .logo-section {
        .logo {
            width: 338px;
            @include mobile {
                width: 280px;
            }
        }
    }
}

.country-search {
    text-align: left;
    margin: 0;
    color: $brand-white;
    /*background-color: rgba(20, 20, 20, 0.57);*/
    @include mobile {
        padding: 15px;
    }
    .title {
        font-family: 'santander-headline-bold';
        font-size: 24px;
        color: $brand-white;
    }
    .subtitle {
        font-family: 'santander-headline-regular';
        font-size: 1em;
        font-weight: normal;
    }
    .title-wrapper {
        margin-bottom: 25px;
        letter-spacing: 0.5px;
    }
}

.wrapper-bloc {
    align-items: center;
}

.logo-section {
    .title {
        font-family: 'santander-headline-bold';
        color: $brand-white;
        font-size: 1.25em;
        letter-spacing: 0.5px;
        margin-top: 25px;
        line-height: 1.55em;
    }
    .subtile {
        font-family: 'santander-headline-regular';
        letter-spacing: 0.5px;
        display: block;
        font-weight: normal;
    }
}

.search-section {
    margin-top: 74px;
}

.container-section {
    padding-top: 78px;
    .wrapper-section {
        display: flex;
        max-width: 55%;
        @include mobile {
            max-width: 100%;
        }
    }
}

.wrapper-rubric {
    display: flex;
    text-align: left;
    color: $brand-white;
    margin-bottom: 55px;
    @include mobile {
        margin-bottom: 50px;
    }
    .title {
        font-family: 'santander-headline-bold';
        font-size: 1.3em;
        color: $brand-white;
        margin-bottom: 2px;
    }
    .text-centent {
        font-family: 'santander-headline-regular';
        font-size: 1.125em;
    }
    .eeicon {
        font-size: 58px;
        color: #DEEDF2;
    }
}

.item-picto {
    margin-right: 1em;
    margin-bottom: 1em;
}
.wrapper-search-field {
    position: relative;
    display: flex;
    margin-right: 10%;
    @include xs-mobile {
        display: block;
        margin-right: 0;
    }
    select {
        height: 64px !important;
    }
    .select2-container {
        background-color: #ffffff;
        border: 3px solid #E1E1E1;
        border-radius: 39px;
        height: 64px;
        .select2-selection--multiple {
            background-color: transparent;
            border-radius: 39px;
            height: 100% !important;
            border-color: transparent !important;
            .select2-selection__rendered {
                height: 100%;
            }
            input {
                &::placeholder {
                    color: #989898;
                    opacity: 1;
                }
            }
        }
    }
    .cta.cta-btn-1 {
        font-family: "santander-micro-text-bold", Arial, sans-serif;
        font-weight: normal;
        font-size: 1.125em;
        position: absolute;
        right: 10px;
        top: calc(50% - 20px);
        min-width: auto;
        @include mobile {
            width: auto;
            font-size: 1em;
        }
        @include xs-mobile {
            position: static;
            width: 100%;
            margin-top: 15px;
            font-size: 1.125em;
        }
    }

}
