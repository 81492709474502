.feed {
    background: $brand-white;
    padding: 18px 22px;
    margin-bottom: 30px;
    width: 100%;
    border-radius: 14px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    .head-title {
        font-size: 1em;
        margin-bottom: 20px;
        max-width: 68%;
    }
    .title {
        font-family: "santander-micro-text-bold", Arial, sans-serif;
        color: $brand-white;
        font-size: 1em;
        font-weight: normal;
    }
    .subtitle {
        color: $brand-white;
        font-size: 1em;
        font-weight: normal;
        margin-top: 8px;
    }
    .section-action {
        text-align: center;
    }
}

.feed-list {
    font-weight: bold;
    font-size: 15px;
    .list-item {
        list-style-type: disc;
        margin-left: 15px;
    }
}

.text-content {
    color: $brand-white;
    font-size: 1em;
}

// feed propal
.feed-propal {
    .feed-wrapper {
        display: flex;
        position: relative;
        min-height: 208px;
        @include mobile {
            min-height: 145px;
        }
    }
    .feed {
        background-color: #DEEDF2;
        box-shadow: none;
        text-align: center;
        .left-content {
            z-index: 1;
            min-width: 90px;
            @include mobile {
                min-width: 45px;
            }
            .illustration-phone {
                max-width: 134px;
                position: absolute;
                left: -20px;
                top: -16px;
                @include mobile {
                    max-width: 75px;
                    left: 3px;
                    top: auto;
                }
            }
            .cta {
                max-width: calc(100% - 45px);
                position: absolute;
                bottom: 20px;
                z-index: 2;
            }
        }
        .right-content {
            margin-left: auto;
            text-align: left;
            @include mobile {
                margin-left: 45px;
            }
        }
        .text-content {
            font-family: 'santander-headline-regular', Arial, sans-serif;
            color: #37474E;
            margin-bottom: 55px;
        }
    }
}

.feed.feed-discover {
    color: $brand-white;
    font-family: 'santander-headline-regular', Arial, sans-serif;
    background: #1E999A url("/build/images/globe.png") right 5px no-repeat;
    box-shadow: none;
    .head-title {
        margin-bottom: 0;
    }
    .subtitle {
        margin-top: 10px;
    }
    .text-content {
        font-size: 1em;
    }
    .feed-list {
        font-weight: normal;
        font-size: 1em;
    }
    p {
        margin-bottom: 0.5em;
    }
    .cta.cta-btn-2 {
        margin-top: 0.5em;
    }
}