html {
    height: 100%;
}
body {
    font-family: "santander-micro-text", Arial, sans-serif;
    font-size: 16px;
    background-color: #363636;
    color: #000000;
    line-height: 1.38;
    overflow-x: hidden;
    position: relative;
    height: 100%;
}
.graphic-kit {
    background: #f9f8f8;
}
.header {
    color: $brand-white;
    height: 50px;
    background: #2F2F2F;
    display: block;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 2;
}

.section-guide {
    margin-top: 30px;
}
