/************/

// Variables

/************/

$desktop: 992px;
$mobile: 991px;
$xs-mobile: 576px;

/************/

// Mixins

/************/
@mixin xs-mobile {
    @media (max-width: #{$xs-mobile}) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: #{$mobile}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$desktop}) {
        @content;
    }
}
