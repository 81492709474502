/*section.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}*/
.site-footer {
    background-color: #363636;
    padding: 45px 0 20px;
    font-size: 15px;
    line-height: 24px;
    color: $brand-white;
}

.site-footer hr {
    border-top-color: #bbb;
    opacity: 0.5
}

.site-footer hr.small {
    margin: 20px 0
}

.site-footer h6 {
    color: $brand-white;
    font-size: 17px;
    line-height: 20px;
    margin-top: 5px;
    font-weight: bold;
}

.site-footer a {
    color: $brand-white;
}

.site-footer a:hover {
    text-decoration: underline;
    color: $brand-white;
}

.footer-links {
    padding-left: 0;
    list-style: none
}

.footer-links li {
    display: block
}

.footer-links a {
    color: $brand-white;
}

.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
    text-decoration: underline;
    color: $brand-white;
}

.footer-links.inline li {
    display: inline-block
}

.copyright-text {
    margin: 0
}

.container-bottom {
    font-size: 13px;
    .text-justify {
        opacity: 0.6;
        line-height: 20px;
    }
}

@media (max-width:991px) {
    .site-footer [class^=col-] {
        margin-bottom: 30px
    }
    .container-top {
        text-align: center;
    }
}

@media (max-width:767px) {
    .site-footer {
        padding-bottom: 0
    }
    .site-footer .copyright-text {
        text-align: center
    }
}
