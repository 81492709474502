@font-face {
	font-family: "eefont";
	src: url("eefont.eot?8ee9fda388b3d3370ec7fe02b3d5d975?#iefix") format("embedded-opentype"),
url("eefont.woff2?8ee9fda388b3d3370ec7fe02b3d5d975") format("woff2"),
url("eefont.woff?8ee9fda388b3d3370ec7fe02b3d5d975") format("woff"),
url("eefont.ttf?8ee9fda388b3d3370ec7fe02b3d5d975") format("truetype"),
url("eefont.svg?8ee9fda388b3d3370ec7fe02b3d5d975#eefont") format("svg");
}

.eeicon {
	line-height: 1;
}

.eeicon:before {
	font-family: eefont !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.eeicon-calendar:before {
	content: "\f101";
}
.eeicon-club:before {
	content: "\f102";
}
.eeicon-event:before {
	content: "\f103";
}
.eeicon-file:before {
	content: "\f104";
}
.eeicon-location:before {
	content: "\f105";
}
.eeicon-lock:before {
	content: "\f106";
}
.eeicon-market:before {
	content: "\f107";
}
.eeicon-news:before {
	content: "\f108";
}
.eeicon-organizer:before {
	content: "\f109";
}
.eeicon-report:before {
	content: "\f10a";
}
.eeicon-suggestion:before {
	content: "\f10b";
}
