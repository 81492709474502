$brand-white_smoke: #f1f0ec;
$brand-white: #fff;
$blue-dk4: #002649;
$medium_grey_st: #5a5a5a;
$seashell: #f1f1f1;
$red_st: #ff0000;
$color_storm_dust_approx: #666;
$blanc: #FFF;
$light_grey_st: #999999;
$ibk-bleufonce: #0056A1;
// from stcV2
$blanc: #FFF;
$grisfonce: #3a3d47;
$marron: #997550;
$seashell: #f1f1f1;
$bleuhome:#336699;
$bleuciel: #00aeef;
$lightblue :#0089ff;
$darkmoderateblue: #336699;
$grissouris :#EEE;
$gray: #AAA;
$ibk-bleufonce: #0056A1;
$kbc-bleufonce: #003665;
$silver: #cdcdcd;
$cacib-rose: #ac3075;
$cacib-gris: #e7e8ec;
$cacib-grisfonce: #455972;
$cacib-text-gris: #495a70;
$cacib-grisfooter: #1c1c1b;
$cacib-grismedium: #666;
$lcl-jaune: #ffd300;
$lcl-bleufonce: #003399;
$lcl-bleuciel: #00bbef;
$lcl-orange: #ff910f;
$lcl-gris: #efefef;
$lcl-grisbleu: #d3e1e5;
$lcl-grisclair: #eeeeee;
///////////////////////////////////////////////////////////////

/* Color definition by project are now in the project folder */

///////////////////////////////////////////////////////////////
////////////////////

/* Alert messages */

////////////////////
// Success
$color_killarney: #3c763d;
$color_willow_brook: #dff0d8;
$color_beryl_green: #d6e9c6;
$color_pixie_green: #c1e2b3;
$color_lunar_green: #2b542c;
// Infos
$color_calypso: #31708f;
$color_link_water: #d9edf7;
$color_charlotte: #bce8f1;
$color_blizzard_blue: #a6e1ec;
$color_san_juan: #245269;
// Warning
$color_pesto: #8a6d3b;
$color_solitaire: #fcf8e3;
$color_champagne: #faebcc;
$color_wheat: #f7e1b5;
$color_shingle_fawn: #66512c;
// Danger, Error
$color_apple_blossom: #a94442;
$color_vanilla_ice: #f2dede;
$color_oyster_pink: #ebcccc;
$color_cavern_pink: #e4b9c0;
$color_lotus: #843534;
////////////////////////

/* End Alert messages */

////////////////////////
// colors to sort
$color_alto_approx: #ddd;
$white: #fff;
$color_tapa_approx: #777;
$color_mine_shaft_approx: #333;
$color_fuscous_gray_approx: #555;
$color_mercury_approx: #e7e7e7;
$color_celeste_approx: #ccc;
$color_cod_gray_approx: #080808;
$color_star_dust_approx: #9d9d9d;
$color_tundora_approx: #444;
$black_50: rgba(0, 0, 0, .5);
$wild_sand: #f4f4f4;
$color_bon_jour_approx: #dfdfdf;
$black: #000;
$color_mountain_mist_approx: #939393;
$color_denim_approx: #0c6db3;
$color_concrete_approx: #f3f3f3;
$concrete: #f2f2f2;
$color_lily_white_approx: #e5f2ff;
$color_picton_blue_approx: #33a2f1;
$yellow: #ff0;
$color_blumine_approx: #23527c;
$color_steel_blue_approx: #337ab7;
$color_st_tropaz_approx: #286090;
$color_regent_st_blue_approx: #afd9ee;
$color_astra_approx: #f7ecb5;
$color_rose_fog_approx: #e4b9b9;
$color_gallery_approx: #eee;
$color_maroon_flush_approx: #c7254e;
$color_fantasy_approx: #f9f2f4;
$black_25: rgba(0, 0, 0, .25);
$color_wild_sand_approx: #f5f5f5;
$color_alabaster_approx: #f9f9f9;
$color_gray_nurse_approx: #e8e8e8;
$color_tea_green_approx: #d0e9c6;
$color_tropical_blue_approx: #c4e3f3;
$color_pipi_approx: #faf2cc;
$mercury: #e5e5e5;
$black_7_5: rgba(0, 0, 0, .075);
$color_fern_approx: #67b168;
$color_twine_approx: #c0a16b;
$color_new_york_pink_approx: #ce8483;
$black_12_5: rgba(0, 0, 0, .125);
$color_stack_approx: #8c8c8c;
$color_silver_chalice_approx: #adadad;
$color_quill_gray_approx: #d4d4d4;
$color_astral_approx: #2e6da4;
$color_big_stone_approx: #122b40;
$color_chathams_blue_approx: #204d74;
$color_apple_approx: #4cae4c;
$color_parsley_approx: #255625;
$color_fern_green_approx: #398439;
$color_viking_approx: #5bc0de;
$color_scooter_approx: #31b0d5;
$color_elm_approx: #1b6d85;
$color_eastern_blue_approx: #269abc;
$color_casablanca_approx: #f0ad4e;
$color_fuel_yellow_approx: #eea236;
$color_carrot_orange_approx: #ec971f;
$color_hawaiian_tan_approx: #985f0d;
$color_meteor_approx: #d58512;
$color_chestnut_rose_approx: #d9534f;
$color_valencia_approx: #d43f3a;
$color_persian_red_approx: #c9302c;
$color_persian_plum_approx: #761c19;
$color_mexican_red_approx: #ac2925;
$black_15: rgba(0, 0, 0, .15);
$black_17_5: rgba(0, 0, 0, .175);
$color_shark_approx: #262626;
$white_10: rgba(255, 255, 255, .1);
$color_desert_storm_approx: #f8f8f8;
$color_chicago_approx: #5e5e5e;
$color_suva_gray_approx: #888;
$color_log_cabin_approx: #222;
$color_woodsmoke_approx: #010010;
$black_10: rgba(0, 0, 0, .1);
$white_15: rgba(255, 255, 255, .15);
$color_botticelli_approx: #c7ddef;
$black_5: rgba(0, 0, 0, .05);
$black_20: rgba(0, 0, 0, .2);
$black_60: rgba(0, 0, 0, .6);
$color_black_haze_approx: #f7f7f7;
$color_cararra_approx: #ebebeb;
$black_0_01: rgba(0, 0, 0, .0001);
$color_storm_dust_approx: #666;
$color_bright_red_approx: #a30000;
$blue_ribbon: #06f;
$color_nobel_approx: #b6b6b6;
$color_prussian_blue_approx: #003859;
$red: #f00;
$blaze_orange: #f60;
$color_lemon_grass_approx: #9d9387;
$color_tide_approx: #beb6ab;
$color_bronco_approx: #aba195;
$gray: #808080;
$color_dove_gray_approx: #6e6e6e;
$color_yellow_sea_approx: #f4ad01;
$color_heliotrope_approx: #c84ef0;
$color_orange_peel_approx: #ff9c00;
$color_kelp_approx: #42453a;
$color_westar_approx: #dcded5;
$color_pumice_approx: #cacdc3;
$color_aqua_squeeze_approx: #e6eff1;
$color_japanese_laurel_approx: #090;
$color_golden_tainoi_approx: #fc6;
$color_bermuda_gray_approx: #6781b1;
$color_deco_approx: #d0e2a1;
$color_caper_approx: #deebbb;
$color_chrome_white_approx: #ecf4d7;
$color_spring_wood_approx: #f7fbed;
$color_blue_haze_approx: #bbc7dc;
$color_mystic_approx: #dfe6ef;
$color_mariner_approx: #3680ca;
$color_malibu_approx: #73b3f2;
$color_gray_chateau_approx: #a4a9af;
$gallery: #efefef;
$color_havelock_blue_approx: #3e97d6;
$color_hurricane_approx: #877f76;
$color_pink_swan_approx: #bbb;
$color_science_blue_approx: #0071cf;
$color_sushi_approx: #81b941;
$color_winter_hazel_approx: #d9d490;
$color_manz_approx: #f7ed7c;
$color_tulip_tree_approx: #f6b43a;
$color_picasso_approx: #f9f298;
$color_guardsman_red_approx: #cf0808;
$alto: #dbdbdb;
$black_30: rgba(0, 0, 0, .3);
$color_concord_approx: #7d7d7d;
$color_merino_approx: #f4f2e9;
$color_cotton_seed_approx: #c1b9b9;
$color_cod_gray_25_approx: rgba(6, 6, 6, .25);
$color_curious_blue_approx: #3598db;
$color_catskill_white_approx: #f0f5f7;
$color_pattens_blue_approx: #deeef9;
$color_iron_approx: #d7d7d7;
$color_shakespeare_approx: #5dade2;
$color_gulf_blue_approx: #00005e;
$color_endeavour_approx: #005aac;
$color_pumice_75_approx: rgba(200, 200, 200, .75);
$color_delta_approx: #a4a4a3;
$blue: #00f;
$color_scarlet_approx: #e52600;
$color_dodger_blue_approx: #0108de;
$color_fuscous_gray_75_approx: rgba(80, 80, 80, .75);
$color_green_approx: #00bf00;
$color_picton_blue_60_approx: rgba(51, 162, 241, .6);
$color_well_read_approx: #aa3538;
$color_cornflower_blue_approx: #5897fb;
$color_royal_blue_approx: #3875d7;
$color_venice_blue_approx: #095082;
$mine_shaft: #323232;
$color_shady_lady_approx: #a9a9a9;
$color_azure_radiance_approx: #007aff;
$white_50: rgba(255, 255, 255, .5);
$color_coral_red_approx: #f34141;
$white_20: rgba(255, 255, 255, .2);
$black_70: rgba(0, 0, 0, .7);
$color_tapa_70_approx: rgba(119, 119, 119, .7);
$color_coral_red_80_approx: rgba(243, 65, 65, .8);
$color_zorba_approx: #a2958a;
$color_boston_blue_approx: #428bca;
$color_electric_lime_approx: #c6ff00;
$color_mandy_approx: #dd5454;
$color_sunset_orange_approx: #f84a4a;
$color_pastel_green_approx: #8dea7b;
$color_green_yellow_approx: #a9ff3a;
$color_jaffa_approx: #e88239;
$color_bamboo_approx: #dc5b00;
$color_persian_pink_approx: #ff8ed0;
$color_hot_pink_approx: #ff58b9;
$color_lavender_approx: #aa7eff;
$color_purple_heart_approx: #7a38f7;
$color_corn_approx: #dcbd00;
$color_school_bus_yellow_approx: #ffdb00;
$color_midnight_blue_approx: #003768;
$color_cerulean_approx: #009ee2;
$white_60: rgba(255, 255, 255, .6);
$red_st: #ff0000;
$dark_red_st: #c80000;
$dark_grey_st: #535353;
$darker_grey_st: #3f3f3f;
$medium_grey_st: #5a5a5a;
$light_grey_st: #999999;
$map_st_dark: #ff0007;
$map_st_light: #f3a5a5;
$st_dark_gold: #8E6E33;
$st_light_gold: #b28f50;
$st_gold: #CC9D49;
$st_yellow_gold: #ffcd73;

/*Media Queries*/

$small: "(min-width: 768px)";
$medium: "(min-width: 992px)";
$large: "(min-width: 1200px)";
$xlarge: "(min-width: 1300px)";
$max-xx-small: "(max-width: 450px)";
$max-x-small: "(max-width: 600px)";
$max-small: "(max-width: 767px)";
$max-medium: "(max-width: 991px)";
$max-large: "(max-width: 1200px)";

$brand-red: #ec0000;
$brand-gray: #363636;
$brand-midgray1: #464646;
$brand-midgray2: #a1a1a1;
$brand-lightgray: #f9f9f9;
$brand-white_smoke: #f1f0ec;
$brand-white: #fff;
$font-size: 13px;
$btn-link-disabled-color: #ccc;
$input-border: #ccc;
$padding-large-horizontal: 20px;
$border-radius-small: 2px;
