.cont-page-error {
  text-align: center;
  margin-bottom: 4em;
  h1 {
    display: block;
    font-size: 5.6em;
    text-transform: uppercase;
    font-weight: 700;
    padding: 0;
    margin-top: .5em;
    margin-bottom: .05em;
    border: none;
    color: #5a5a5a
  }
  p.message-page-error {
    font-size: 1.5em;
    padding: 0 0 22px;
    border-bottom: 1px solid #777;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 1.5em;
    color: #5a5a5a
  }
  p.txt-page-error {
    color: #666;
    font-size: 1.2em;
    text-align: center;
    font-style: normal;
    line-height: 1.8em
  }
}