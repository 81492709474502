.container {
    max-width: 960px;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.nav li {
    width: 100%;
    // padding: 0 37px;
    position: relative;
    &:hover {
        .add-interex {
            background: url("/build/images/add.svg") center no-repeat;
            content: "";
            width: 20px;
            height: 20px;
            background-color: $brand-red;
            color: $brand-white;
            border-radius: 100%;
            opacity: .5;
            position: absolute;
            left: 10px;
            top: 10px;
            display: inline-block;
            vertical-align: middle;
            cursor: pointer;
        }
        .add-interex.selected {
            background: url("/build/images/close-cross.svg") center no-repeat;
            content: "";
            width: 20px;
            height: 20px;
            background-color: $brand-red;
            color: $brand-white;
            border-radius: 100%;
            opacity: .5;           
            cursor: pointer;
        }
    }
    .add-interex.selected {
        background: url("/build/images/close-cross.svg") center no-repeat;
        display: inline-block;
        content: "";
        width: 20px;
        height: 20px;
        background-color: $brand-red;
        color: $brand-white;
        border-radius: 100%;
        opacity: .5;           
        cursor: pointer;
        position: absolute;
        left: 10px;
        top: 10px;
    }
}

.nav li a {
    color: #363636;
    font-size: 15px;
    font-weight: normal;
    line-height: 20px;
    text-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.44);
    padding: 10px 40px;
    display: inherit;
    &.selected {
        padding: 10px 40px;
        margin-bottom: 3px;
        background: #f5f5f5;
        display: inherit;
    }
    &:hover {
        background: #f5f5f5;
    }
}

#accordionMenu {
    max-width: 100%;
}

.panel-body {
    padding: 0;
}

.panel-group .panel+.panel {
    margin-top: 0;
    border-top: 0;
}

.panel-group .panel {
    border-radius: 0;
}

.panel-default>.panel-heading {
    color: #333;
    background-color: $brand-white;
    border-color: #e4e5e7;
    padding: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

}

.panel-default>.panel-heading .panel-title {
    display: flex;
    align-items: center;
    &:hover {
        background-color: #f5f5f5;
        .add-interex:not(.expended) {
            background: url("/build/images/add.svg") center no-repeat;
            content: "";
            width: 20px;
            height: 20px;
            background-color: $brand-red;
            color: $brand-white;
            border-radius: 100%;
            opacity: .5;
            position: relative;
            left: 10px;
            margin-right: 10px;
            display: inline-block;
            vertical-align: middle;
            cursor: pointer;
            +a:before {
                display: none !important;
            }
        }
        
    }
    .add-interex.selected:not(.expended) {
        background: url("/build/images/close-cross.svg") center no-repeat;
        background-color: $brand-red;
        content: "";
        width: 20px;
        height: 20px;
        color: $brand-white;
        border-radius: 100%;
        opacity: .5;
        position: absolute;
        left: 10px;
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        +a:before {
            display: none !important;
        }
    }
}

.panel-default>.panel-heading a {
    display: flex;
    align-items: center;
    padding: 10px;
    text-decoration: none;
    color: #363636;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    width: 100%;
    text-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.44);
    &.selected {
        background: #f5f5f5;
        padding: 10px 10px 10px 40px;
    }
}

.fa-arrow-right {
    float: right;
}

#accordionMenu {
    .panel-title {
        a[aria-expanded="false"] {
            .arrow-left {
                display: none;
            }
        }
        a[aria-expanded="true"] {
            .arrow-right {
                display: none;
            }
            &:before {
                background: url("/build/images/arrow-left.png");
                content: '';
                width: 24px;
                height: 24px;
                margin-right: 8px;
                display: inline-block;
                opacity: .8;                
            }            
        }
        .arrow {
            float: right;
            margin-left: auto;
            opacity: .8;
        }
    }
}
